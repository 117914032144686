<template>
  <div class="dialog">
    <div class="buy-order">
      <div class="buy-order-bottle" :class="{ removePadding: !firstImage }">
        <img
          v-if="firstImage"
          :alt="getProduct.title"
          :src="firstImage"
          height="58"
          width="58"
        />
        <img
          v-else
          :src="require('@/assets/svg/v2/bottle.svg')"
          alt="Product not found image"
          class="empty-bottle-picture"
          height="58"
          width="58"
        />
        <div>
          <h2>{{ getProduct.title }}</h2>
          <p>{{ getProduct.ticker }}</p>
        </div>
      </div>

      <form>
        <div class="form-input-bloc-header">
          <div class="input-line">
            <h3>{{ $t('product_page.orders.buy_price') }}</h3>
            <el-input
              v-model="buyForm.price"
              :class="{ errorInput: buyFormRegex.price }"
              class="buy-order-input"
              placeholder="50-30000"
            />
          </div>
          <div class="input-line">
            <h3>{{ $t('product_page.orders.sell_quantity') }}</h3>
            <el-input
              v-model="buyForm.quantity"
              :class="{ errorInput: buyFormRegex.quantity }"
              class="buy-order-input"
              max="10"
              min="1"
              placeholder="1"
              type="number"
            />
          </div>
        </div>

        <div class="buy-form-options">
          <h4>{{ $t('product_page.orders.buy_options') }}</h4>
          <div class="buy-end-date-bloc">
            <div class="buy-end-date-inputs">
              <div class="buy-end-date-actions">
                <div class="buy-end-date-select">
                  <img alt="End date" src="@/assets/svg/v2/settings/hour.svg" />
                  <h4>{{ $t('product_page.orders.buy_end_date') }}</h4>
                </div>

                <el-switch
                  v-model="buyForm.endDate"
                  class="switch"
                  style="
                    --el-switch-on-color: #2667ff;
                    --el-switch-off-color: #555555;
                  "
                />
              </div>

              <div v-if="buyForm.endDate" class="buy-select-end-date">
                <el-date-picker
                  v-model="buyForm.date"
                  :class="{
                    errorInput:
                      buyFormRegex.countdown || buyFormRegex.dateBeforeNow,
                  }"
                  class="generic-border"
                  format="DD/MM/YYYY"
                  placeholder="Choose a day"
                  type="date"
                  value-format="YYYY-MM-DD"
                />
                <el-input
                  v-model="buyForm.time"
                  :class="{
                    errorInput:
                      buyFormRegex.countdown || buyFormRegex.dateBeforeNow,
                  }"
                  class="buy-order-input-small"
                  placeholder="12:00"
                  value-format="HH:mm"
                  maxlength="5"
                  prefix-icon="el-icon-time"
                />
              </div>
            </div>
          </div>
        </div>
      </form>

      <button :disabled="isLoading"
        v-loading="isLoading"
         class="create-order-btn generic-btn" @click="updateOrder()">
        {{ $t('product_page.orders.update_order') }}
      </button>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import m from 'moment'
import { ElMessage } from 'element-plus'

export default {
  emits: ['close'],
  props: {
    order: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isLoading: false,
      buyForm: {
        price: null,
        quantity: 1,
        endDate: false,
        date: null,
        time: null,
      },
      buyFormRegex: {
        price: false,
        quantity: false,
        countdown: false,
        dateBeforeNow: false,
      },
    }
  },
  mounted() {
    this.buyForm.price = this.order.price / 100
    this.buyForm.quantity = this.order.quantity
  },
  computed: {
    ...mapGetters({
      getCreateOrderDialog: 'getCreateOrderDialog',
      getProduct: 'getProduct',
    }),
    firstImage() {
      if (this.getProduct.files_attachments) {
        return this.getProduct.files_attachments[0]
      }
      return ''
    },
    buyDateFormat() {
      if (this.buyForm.date !== null && this.buyForm.time !== null) {
        return `${this.buyForm.date} ${this.buyForm.time}`
      } else {
        return m().add(1, 'years').format('YYYY-MM-DD HH:mm')
      }
    },
  },
  watch: {
    'buyForm.time': function () {
      if (this.buyForm.time) {
        let slashCount = 0
        for (var i = 0; i < this.buyForm.time; i++) {
          if (this.buyForm.time[i] === ':') {
            slashCount++
          }
        }

        if (slashCount > 1) {
          this.buyForm.time = ''
        }

        if (this.buyForm.time.length === 2 && slashCount === 0) {
          this.buyForm.time += ':'
        }
      }
    },

    buyDateFormat() {
      const now = m().add(1, 'hours').format('YYYY-MM-DD HH:mm')

      if (this.buyForm.date && this.buyForm.time) {
        const isBeforeNow = m(
          `${this.buyForm.date} ${this.buyForm.time}`
        ).isBefore(now)

        if (isBeforeNow) {
          this.buyForm.time = null
          this.buyForm.date = null
        } else {
          this.buyFormRegex.dateBeforeNow = false
        }
      }
    },
  },
  methods: {
    ...mapActions({
      updateBuyOrder: 'updateBuyOrder',
    }),
    loseOrder() {
      this.$store.commit('CREATE_ORDER_DIALOG', null)
    },
    async updateOrder() {
      const priceRegex = /^[1-9][0-9]{0,5}$/
      const quantityRegex = /^[0-9]{1,3}$/
      const isbnRegex = /^[0-9a-zA-Z]{1,15}$/
      const hourRegex = /^(?:[0-9]{2}):(?:[0-9]{2})$/

      const payload = {
        uuid: this.order.uuid,
        type: null,
        isbn: this.getProduct.isbn,
        price: this.buyForm.price,
        quantity: this.buyForm.quantity,
        countdown: `${this.buyForm.date} ${this.buyForm.time}`,
      }

      if (isbnRegex.test(this.buyForm.isbn)) {
        this.buyFormRegex.quantity = false
      } else {
        this.buyFormRegex.quantity = true
      }

      if (
        priceRegex.test(this.buyForm.price) &&
        parseInt(this.buyForm.price) > 30 &&
        parseInt(this.buyForm.price) < 30000
      ) {
        this.buyFormRegex.price = false
      } else {
        this.buyFormRegex.price = true
      }

      if (this.buyForm.time) {
        if (hourRegex.test(this.buyForm.time)) {
          this.buyFormRegex.time = false
        } else {
          this.buyFormRegex.time = true
        }
      }

      if (quantityRegex.test(this.buyForm.quantity)) {
        this.buyFormRegex.quantity = false
      } else {
        this.buyFormRegex.quantity = true
      }

      if (this.buyForm.endDate) {
        if (m(payload.countdown).isValid()) {
          this.buyFormRegex.countdown = false

          const now = m().toString()
          const countdownAddHour = m(payload.countdown, 'YYYY-MM-DD HH-mm').add(
            1,
            'h'
          )

          if (m(now).isBefore(countdownAddHour)) {
            this.buyFormRegex.dateBeforeNow = false
          } else {
            this.buyFormRegex.dateBeforeNow = true
          }
        } else {
          this.buyFormRegex.countdown = true
        }
      } else {
        delete payload.countdown
      }

      payload.type = 0

      if (
        !this.buyFormRegex.countdown &&
        !this.buyFormRegex.price &&
        !this.buyFormRegex.quantity &&
        !this.buyFormRegex.dateBeforeNow &&
        payload.type === 0 &&
        !this.buyFormRegex.time
      ) {
        this.isLoading = true
        await this.updateBuyOrder(payload).then(() => {
          ElMessage({
            message: 'Ordre mis à jour avec succès',
            type: 'success',
            grouping: true,
          })
          this.isLoading = false
          this.$emit('close')
          this.$store.commit('CREATE_ORDER_DIALOG', false)
        })
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/styles/root.scss';

.dialog {
  .buy-order {
    background: #333333;

    .buy-order-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-radius: 20px 20px 0px 0px;
      background: linear-gradient(
        180deg,
        rgba(132, 204, 22, 0.16) 0%,
        rgba(132, 204, 22, 0) 100%
      );
      border-bottom: 1px solid $border;

      .buy-order-header-title {
        display: flex;
        align-items: center;

        div {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 38px;
          height: 38px;
          background: #84cc16;
          border-radius: 50%;
          margin-right: 14px;
        }
      }

      button {
        background: transparent;
        border-radius: 76px;
        border: 1px solid $border;
        background: #444;
        width: 38px;
        height: 38px;

        img {
          margin-top: 4px;
        }
      }
    }

    .buy-order-bottle {
      display: flex;
      align-items: center;
      padding-left: 24px;
      padding-bottom: 26px;
      margin: 25px auto 26px auto;
      border-bottom: 1px solid $border;

      div {
        margin-left: 16px;

        h2 {
          color: white;
          font-size: 18px;
          font-weight: 600;
        }

        p {
          border: 1px solid $border;
          color: $grey40;
          margin-top: 12px;
          border-radius: 6px;
          padding: 2px 4px;
          max-width: 120px;
          text-align: center;
        }
      }
    }

    form {
      flex-direction: column;
      padding-left: 24px;
      padding-right: 24px;

      .form-input-bloc,
      .form-input-bloc-flex {
        margin-top: 18px;

        h3 {
          color: white;
          font-size: 16px;
          font-weight: 600;
        }
      }

      .form-input-bloc-flex {
        display: flex;
        align-items: center;

        h3 {
          width: 110px;
        }
      }

      .buy-form-options {
        margin-top: 30px;

        h4 {
          color: $subtitle;
          font-size: 16px;
          font-weight: 600;
        }

        .buy-end-date-bloc {
          .buy-end-date-inputs {
            .buy-end-date-actions {
              display: flex;
              justify-content: space-between;
              margin-top: 20px;

              div {
                display: flex;

                h4 {
                  color: $subtitle;
                  font-size: 16px;
                  font-weight: 500;
                  margin-left: 8px;
                }
              }
            }

            .buy-select-end-date {
              display: flex;
              gap: 20px;
              margin-top: 12px;
               margin-bottom: 10px;
            }
          }
        }
      }
    }

    .create-order-btn {
      margin-top: 51px;
      width: 194px;
      margin: auto;
      margin-bottom: 15px;
    }
  }
}

.input-line {
  display: flex;
  flex-direction: column;
  margin-right: 20px;
  font-size: 14px;
  font-weight: 600;
  gap: 10px;
}

.form-input-bloc-header {
  display: flex;
  justify-content: space-between;
  color: #fff;
  width: 65%;
}

.buy-order-input-small {
  border-radius: 5px;
  max-width: 30%;
  border: 1px solid $border;
}

.buy-order-input {
  border-radius: 5px;
  border: 1px solid $border;
}

@media screen and (max-width: 768px) {
  .form-input-bloc,
  .form-input-bloc-flex {
    margin-top: 18px;
  }

  .form-input-bloc-header {
    width: 100%;
    align-items: flex-start;
  }

  .image-preview {
    margin-right: 0;
    max-width: 80px;
  }

  .package-dimensions {
    flex-wrap: wrap;
    flex-direction: row;

    .form-input-bloc {
      width: 48%;
      margin-bottom: 10px;
      margin-right: 4%;
    }

    .form-input-bloc:nth-child(2n) {
      margin-right: 0;
    }
  }

  .custom-file-input,
  input[type='file']::file-selector-button {
    width: 100%;
  }

  .dialog .buy-order {
    padding: 10px;
  }

  .dialog .buy-order-header {
    padding: 12px;
    align-items: flex-start;

    button {
      width: 30px;
      height: 30px;

      img {
        margin-top: 2px;
      }
    }
  }

  .dialog .buy-order-bottle {
    flex-direction: column;
    align-items: flex-start;
    padding: 10px;

    div {
      margin-left: 0;
      margin-top: 10px;
    }

    p {
      max-width: 100%;
    }
  }

  .buy-form-options .buy-end-date-inputs {
    align-items: flex-start;

    .buy-end-date-actions {
      align-items: flex-start;

      div {
        margin-top: 10px;
        margin-bottom: 10px;
      }
    }

    .buy-select-end-date {
      align-items: flex-start;
      margin-top: 10px;
    }
  }

  .create-order-btn {
    margin-top: 10px;
    width: 100%;
  }
}

@media screen and (max-width: 500px) {
  .form-input-bloc-header {
    width: 100%;
  }

  .image-preview {
    max-width: 60px;
  }

  .dialog .buy-order {
    padding: 5px;
  }

  .dialog .buy-order-header {
    padding: 8px;
  }

  .buy-form-options .buy-end-date-inputs .buy-end-date-actions,
  .buy-form-options .buy-end-date-inputs .buy-select-end-date {
    align-items: flex-start;
  }

  .buy-order-bottle div h2 {
    font-size: 16px;
  }

  .buy-order-bottle div p {
    font-size: 14px;
  }

  .buy-form-options h4 {
    font-size: 14px;
  }

  .dialog {
    .buy-order {
      .buy-order-bottle {
        flex-direction: row;
        padding: 5px;
        margin: -20px auto 10px auto;
      }
      
      form {
        padding-left: 0;
        padding-right: 0;

        .buy-form-options {
          margin-top: 10px;
          .buy-end-date-bloc {
            .buy-end-date-inputs {
              .buy-end-date-actions {
                margin-top: 10px;
              }
            }
          }
        }
      }

      .create-order-btn {
        margin-top: 20px;
      }
    }
  }

  .package-dimensions .form-input-bloc {
    width: 48%;
    margin-bottom: 10px;
    margin-right: 2%;
  }

  .package-dimensions .form-input-bloc:nth-child(2n) {
    margin-right: 0;
  }
}
</style>
