<template>
  <div v-if="getCreateOrderDialog === 'updateBlockTrade'" class="dialog">
    <div class="sell-order">
      <div class="sell-order-bottle" :class="{ removePadding: !firstImage }">
        <img
          v-if="firstImage"
          :alt="getProduct.title"
          :src="firstImage"
          height="58"
          width="58"
        />
        <img
          v-else
          :src="require('@/assets/svg/v2/bottle.svg')"
          alt="Product not found image"
          class="empty-bottle-picture"
          height="58"
          width="58"
        />
        <div>
          <h2>{{ getProduct.title }}</h2>
          <p>{{ getProduct.ticker }}</p>
        </div>
      </div>
      <form
        action=""
        enctype="multipart/form-data"
        @submit.prevent="updateSellOrder"
      >
        <div class="form-input-bloc-header">
          <div class="input-line">
            <h3>{{ $t('product_page.orders.sell_price') }}</h3>
            <el-input
              v-model="sellForm.price"
              :class="{ errorInput: sellFormRegex.price }"
              class="sell-order-input"
              placeholder="50-30000"
            />
          </div>
          <div class="input-line">
            <h3>{{ $t('product_page.orders.sell_quantity') }}</h3>
            <el-input
              v-model="sellForm.quantity"
              :class="{ errorInput: sellFormRegex.quantity }"
              class="sell-order-input"
              max="10"
              min="1"
              placeholder="1"
              type="number"
            />
          </div>
        </div>
        <div class="package-dimensions">
          <div class="form-input-bloc margin-block-form">
            <h3>{{ $t('product_page.orders.weight_unit') }}</h3>
            <el-select
              v-model="sellForm.weight_unit"
              class="generic-border"
              effect="dark"
            >
              <el-option
                v-for="unit in getLists.unit_of_measurement_weight"
                :key="unit.value"
                :label="unit.label"
                :value="unit.value"
              />
            </el-select>
          </div>
          <div class="form-input-bloc">
            <h3>
              {{ $t('product_page.orders.sell_weight') }} ({{
                sellForm.weight_unit
              }})
            </h3>
            <el-input
              v-model="sellForm.weight"
              :class="{ errorInput: sellFormRegex.length }"
              class="sell-order-input"
              max="20"
              min="1"
              placeholder="1"
              type="number"
            />
          </div>
        </div>
        <div class="package-dimensions">
          <div class="form-input-bloc margin-block-form">
            <h3>{{ $t('product_page.orders.size_unit') }}</h3>
            <el-select
              v-model="sellForm.size_unit"
              class="generic-border"
              effect="dark"
            >
              <el-option
                v-for="unit in getLists.unit_of_measurement_dimensions"
                :key="unit.value"
                :label="unit.label"
                :value="unit.value"
              />
            </el-select>
          </div>
          <div class="form-input-bloc margin-block-form">
            <h3>
              {{ $t('product_page.orders.length') }} ({{ sellForm.size_unit }})
            </h3>
            <el-input
              v-model="sellForm.package_dimensions.length"
              :class="{ errorInput: sellFormRegex.length }"
              class="sell-order-input"
              max="2000"
              min="10"
              placeholder="1"
              type="number"
            />
          </div>
          <div class="form-input-bloc margin-block-form">
            <h3>
              {{ $t('product_page.orders.width') }} ({{ sellForm.size_unit }})
            </h3>
            <el-input
              v-model="sellForm.package_dimensions.width"
              :class="{ errorInput: sellFormRegex.width }"
              class="sell-order-input"
              max="2000"
              min="10"
              placeholder="1"
              type="number"
            />
          </div>
          <div class="form-input-bloc">
            <h3>
              {{ $t('product_page.orders.height') }} ({{ sellForm.size_unit }})
            </h3>
            <el-input
              v-model="sellForm.package_dimensions.height"
              :class="{ errorInput: sellFormRegex.height }"
              class="sell-order-input"
              max="2000"
              min="10"
              placeholder="1"
              type="number"
            />
          </div>
        </div>
        <div class="form-input-bloc centered">
          <h3>{{ $t('product_page.orders.sell_pictures') }}</h3>
          <input
            ref="file"
            :multiple="true"
            accept="image/jpg, image/jpeg, image/png"
            class="custom-file-input"
            name="document"
            type="file"
            @change="files"
          />
          <div v-if="selectedFiles.length > 0">
            <div class="image-previews">
              <div
                v-for="(file, index) in selectedFiles"
                :key="index"
                class="image-preview"
                @mouseenter="showDeleteButton(index)"
                @mouseleave="hideDeleteButton(index)"
              >
                <img
                  :src="file.preview"
                  alt="Selected Image"
                  class="image-preview"
                />

                <button class="delete-button" @click="removeFile(index)">
                  <img
                    id="delete"
                    :src="require(`@/assets/svg/v2/orders/trash.svg`)"
                    alt="Delete"
                  />
                </button>
              </div>
            </div>
          </div>
        </div>
        <span v-if="sellFormRegex.file" class="error" style="margin: 10px 0"
          >{{ $t('product_page.orders.max_min_pictures') }}</span
        >
        <div class="sell-form-options">
        </div>
      </form>
      <button
        :disabled="isLoading"
        v-loading="isLoading"
        class="create-order-btn generic-btn"
        @click.prevent="updateSellOrder"
      >
      {{ $t('product_page.orders.update_order') }}
      </button>
    </div>
  </div>
</template>
<script>
import { ElMessage, ElNotification } from 'element-plus'

import { mapActions, mapGetters } from 'vuex'
import m from 'moment'

export default {
  props: {
    order: {
      type: Object,
      required: true,
    },
  },
  emits: ['close'],
  data() {
    return {
      isLoading: false,
      selectedFiles: [],
      sellForm: {
        price: null,
        weight_unit: 'KGS',
        size_unit: 'CM',
        quantity: 1,
        weight: 0,
        package_dimensions: {
          length: 0,
          width: 0,
          height: 0,
        },
        endDate: false,
        date: null,
        time: null,
      },
      sellFormRegex: {
        price: false,
        quantity: false,
        endDate: false,
        date: false,
        time: false,
        dateBeforeNow: false,
        length: false,
        width: false,
        height: false,
        file: false,
        weight: false,
      },
    }
  },
  mounted() {
    this.updateSellFormFromOrder(this.order)
  },
  computed: {
    ...mapGetters({
      getCreateOrderDialog: 'getCreateOrderDialog',
      getProduct: 'getProduct',
      getLists: 'getLists',
    }),
    firstImage() {
      if (this.getProduct.files_attachments) {
        return this.getProduct.files_attachments[0]
      }
      return ''
    },
    sellDateFormat() {
      if (this.sellForm.date !== null && this.sellForm.time !== null) {
        return `${this.sellForm.date} ${this.sellForm.time}`
      } else {
        return m().add(1, 'years').format('YYYY-MM-DD HH:mm')
      }
    },
  },
  watch: {
    'blockTradeForm.time': function () {
      if (this.blockTradeForm.time) {
        let slashCount = 0
        for (var i = 0; i < this.blockTradeForm.time; i++) {
          if (this.blockTradeForm.time[i] === ':') {
            slashCount++
          }
        }

        if (slashCount > 1) {
          this.blockTradeForm.time = ''
        }

        if (this.blockTradeForm.time.length === 2 && slashCount === 0) {
          this.blockTradeForm.time += ':'
        }
      }
    },
    sellDateFormat() {
      const now = m().add(1, 'hours').format('YYYY-MM-DD HH:mm')

      if (this.sellForm.date && this.sellForm.time) {
        const isBeforeNow = m(
          `${this.sellForm.date} ${this.sellForm.time}`
        ).isBefore(now)

        if (isBeforeNow) {
          this.sellForm.time = null
          this.sellForm.date = null
        } else {
          this.sellFormRegex.dateBeforeNow = false
        }
      }
    },
    order: {
      deep: true,
      handler(newValue) {
        this.updateSellFormFromOrder(newValue)
      },
    },
  },
  methods: {
    ...mapActions({
      updateOrder: 'updateOrder',
      createOrderActions: 'createSellOrder',
      product: 'product',
    }),

    getWeight(bottleSize, weightUnit) {
      const weightItem = this.getLists.minimum_weight.find(
        (item) => item.bottle_size === parseInt(bottleSize)
      )
      if (!weightItem) return null

      switch (weightUnit) {
        case 'KGS':
          return weightItem.weight / 1000
        case 'LBS':
          return weightItem.weight / 453.59237
        case 'OZS':
          return weightItem.weight * 0.035274
        default:
          return null
      }
    },

    updateSellFormFromOrder(order) {
      if (order) {
        this.selectedFiles = order?.pictures?.map((url) => ({
          preview: url,
        }))
        this.sellForm.price = order.price / 100
        this.sellForm.quantity = order.quantity
        this.sellForm.weight_unit = order.weight_unit
        this.sellForm.size_unit = order.size_unit
        this.sellForm.weight = order.package_weight
        this.sellForm.package_dimensions.length =
          order.package_dimensions?.length
        this.sellForm.package_dimensions.width = order.package_dimensions?.width
        this.sellForm.package_dimensions.height =
          order.package_dimensions?.height
      }
    },
    async updateSellOrder() {
      const regex_price = /^[1-9][0-9]{0,5}$/
      const quantity_regex = /^[0-9]{1,3}$/
      const weight_regex = /^[0-3]$/
      const hourRegex = /^(?:[0-9]{2}):(?:[0-9]{2})$/

      const payload = {
        isbn: this.getProduct.isbn,
        quantity: this.sellForm.quantity,
        price: this.sellForm.price,
        weight_unit: this.sellForm.weight_unit,
        size_unit: this.sellForm.size_unit,
        weight: this.sellForm.weight,
        order_uuid: this.order.uuid,
        countdown: `${this.sellForm.date} ${this.sellForm.time}`,
      }

      if (this.sellForm.time) {
        if (hourRegex.test(this.sellForm.time)) {
          this.sellFormRegex.time = false
        } else {
          this.sellFormRegex.time = true
        }
      }

      if (this.sellForm.endDate) {
        if (m(payload.countdown).isValid()) {
          this.sellFormRegex.countdown = false

          const now = m().toString()
          const countdownAddHour = m(payload.countdown, 'YYYY-MM-DD HH-mm').add(
            1,
            'h'
          )

          if (m(now).isBefore(countdownAddHour)) {
            this.sellFormRegex.dateBeforeNow = false
          } else {
            this.sellFormRegex.dateBeforeNow = true
          }
        } else {
          this.sellFormRegex.dateBeforeNow = true
        }
      } else {
        delete payload.countdown
      }

      if (
        regex_price.test(this.sellForm.price) &&
        parseInt(this.sellForm.price) >= 50 &&
        parseInt(this.sellForm.price) <= 30000
      ) {
        this.sellFormRegex.price = false
      } else {
        this.sellFormRegex.price = true
      }

      if (quantity_regex.test(this.sellForm.quantity)) {
        this.sellFormRegex.quantity = false
      } else {
        this.sellFormRegex.quantity = true
      }

      if (weight_regex.test(this.sellForm.weight)) {
        this.sellFormRegex.weight = false
      } else {
        this.sellFormRegex.weight = true
      }

      if (this.getProduct.bottle_size === 500 || this.getProduct.bottle_size === 700 || this.getProduct.bottle_size === 750) {
        if (payload.package_dimensions.length < 10 || payload.package_dimensions.width < 10 || payload.package_dimensions.height < 10) {
          this.blockTradeFormRegex.length = true
          this.blockTradeFormRegex.width = true
          this.blockTradeFormRegex.height = true
        } else {
          this.blockTradeFormRegex.length = false
          this.blockTradeFormRegex.width = false
          this.blockTradeFormRegex.height = false
        }
      }

      if (
        parseInt(this.sellForm.weight) <
        this.getWeight(this.getProduct.bottle_size, this.sellForm.weight_unit) *
          this.sellForm.quantity
      ) {
        this.sellFormRegex.weight = true
      } else {
        this.sellFormRegex.weight = false
      }

      if (this.selectedFiles < 4 || this.selectedFiles > 8) {
        this.sellFormRegex.file = true
        ElMessage({
          message: this.$t('product_page.orders.max_min_pictures'),
          type: 'error',
          grouping: true,
          duration: 5000,
        })
      } else {
        this.sellFormRegex.file = false
      }

      if (
        !this.sellFormRegex.weight &&
        !this.sellFormRegex.quantity &&
        !this.sellFormRegex.price &&
        !this.sellFormRegex.countdown &&
        !this.sellFormRegex.file &&
        !this.sellFormRegex.time
      ) {
        const formData = new FormData()
        formData.append('quantity', payload.quantity)
        formData.append('full_size', payload.full_size)
        formData.append('price', payload.price)
        formData.append('weight', payload.weight)
        formData.append('weight_unit', payload.weight_unit)
        formData.append('size_unit', payload.size_unit)
        formData.append('bottle_size', this.getProduct.bottle_size || '1000')

        if (payload.countdown != undefined) {
          formData.append('countdown', payload.countdown)
        }
        formData.append(
          'package_dimensions',
          JSON.stringify(payload.package_dimensions)
        )
        formData.append('uuid', payload.order_uuid)
        formData.append('isbn', payload.isbn)
        for (
          let fileCount = 0;
          fileCount < this.selectedFiles.length;
          fileCount++
        ) {
          if (!this.selectedFiles[fileCount].preview.includes('blob:')) {
            formData.append(
              'saved_pictures_attachments',
              this.selectedFiles[fileCount].preview
            )
          } else formData.append('files', this.selectedFiles[fileCount].file)
        }
        this.isLoading = true
        await this.updateOrder(formData).then(() => {
          ElNotification.success({
            title: '',
            message: this.$t('orders.update_order'),
            offset: 100,
          })
          ;(this.sellForm = {
            price: null,
            weight_unit: 'kg',
            size_unit: 'cm',
            quantity: 1,
            weight: 0,
            package_dimensions: {
              length: 0,
              width: 0,
              height: 0,
            },
            endDate: false,
            date: null,
            time: null,
          }),
            (this.sellFormRegex = {
              price: false,
                quantity: false,
              endDate: false,
              date: false,
              time: false,
              dateBeforeNow: false,
              length: false,
              width: false,
              height: false,
              file: false,
            }),
            (this.isLoading = false)
            this.$emit('close')
          this.$store.commit('CREATE_ORDER_DIALOG', false)
        })
      }
    },

    closeOrder() {
      this.$store.commit('CREATE_ORDER_DIALOG', null)
    },

    files() {
      for (
        let fileCount = 0;
        fileCount < this.$refs.file.files.length;
        fileCount++
      ) {
        if (this.$refs.file.files) {
          const ext =
            /[A-Za-z0-9_\-()ÀÁÂÃÄÅàáâãäåÒÓÔÕÖØòóôõöøÈÉÊËèéêëÇçÌÍÎÏìíîïÙÚÛÜùúûüÿÑñ]+.(jpg|jpeg|png|JPG|PNG|JPEG)$/
          const fileName = this.$refs.file.files[fileCount].name
          const fileSize = this.$refs.file.files[fileCount].size

          if (!ext.test(fileName) || fileSize > 7000000) {
            ElMessage({
              message: this.$t('product_page.orders.pictures_allowed'),
              type: 'error',
              grouping: true,
              duration: 5000,
            })
          } else {
            const file = {
              file: this.$refs.file.files[fileCount],
              preview: URL.createObjectURL(this.$refs.file.files[fileCount]),
              name: this.$refs.file.files[fileCount].name,
            }

            this.selectedFiles.push(file)
          }
        }
      }
    },
    showDeleteButton(index) {
      this.selectedFiles[index] = {
        ...this.selectedFiles[index],
        showDeleteButton: true,
      }
    },

    hideDeleteButton(index) {
      this.selectedFiles[index] = {
        ...this.selectedFiles[index],
        showDeleteButton: false,
      }
    },

    removeFile(index) {
      event.preventDefault()
      this.selectedFiles.splice(index, 1)
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/styles/root.scss';

.sell-order-input-small {
  border-radius: 5px;
  border: 1px solid $border;
  max-width: 30%;
}

.delete-button {
  display: block;
  margin-top: 5px;
  padding: 5px;
  background: transparent;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background 0.3s;
}
.input-line {
  display: flex;
  flex-direction: column;
  margin-right: 20px;
  font-size: 14px;
  font-weight: 600;
  gap: 10px;
}

.form-input-bloc-header {
  display: flex;
  justify-content: space-between;
  color: #fff;
  width: 65%;
}

delete-overlay {
  position: absolute;
  top: 0;
  right: 0;
  background: rgba(255, 255, 255, 0.8);
  border: none;
  padding: 5px;
  cursor: pointer;
  transition: background 0.3s;
}

.delete-overlay img {
  width: 16px;
  height: 16px;
}

.image-previews {
  display: flex;
  flex-wrap: wrap;
  margin-top: 10px;
}

.centered {
  text-align: center;
}

.image-preview {
  min-height: 80px;
  margin-right: 10px;
  margin-bottom: 10px;
  max-width: 100px;
  text-align: center;
  position: relative;

  img {
    max-width: 100%;
    height: auto;
    border-radius: 5px;
  }

  .delete-button {
    display: none;
    position: absolute;
    top: 5px;
    right: 5px;
    padding: 5px;
    background: transparent;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background 0.3s;
  }

  &:hover {
    .delete-button {
      display: block;
    }
  }

  span {
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.package-dimensions {
  display: flex;
}

.dialog {
  .sell-order {
    background: #333333;

    .sell-order-header-title {
      display: flex;
      align-items: center;

      div {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 38px;
        height: 38px;
        background: $blue;
        border-radius: 50%;
        margin-right: 14px;
      }
    }
  }

  .sell-order-bottle {
    display: flex;
    align-items: center;
    padding-left: 24px;
    padding-bottom: 26px;
    margin: 25px auto 26px auto;
    border-bottom: 1px solid $border;

    div {
      margin-left: 16px;

      h2 {
        color: white;
        font-size: 18px;
        font-weight: 600;
      }

      p {
        border: 1px solid $border;
        color: $grey40;
        margin-top: 12px;
        border-radius: 6px;
        padding: 2px 4px;
        max-width: 120px;
        text-align: center;
      }
    }
  }

  form {
    flex-direction: column;
    padding-left: 24px;
    padding-right: 24px;

    .margin-block-form {
      margin-right: 10px;
    }

    .form-input-bloc {
      margin-top: 18px;

      h3 {
        color: white;
        font-size: 16px;
        font-weight: 600;
        margin-bottom: 8px;
      }
    }

    .package-dimensions {
      width: 100%;

      .form-input-bloc {
        width: 23%;
      }
    }

    .sell-form-options {
      margin-top: 30px;

      h4 {
        color: $subtitle;
        font-size: 16px;
        font-weight: 600;
      }

      .sell-end-date-bloc {
        .sell-end-date-inputs {
          .sell-end-date-actions {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-top: 20px;

            div {
              display: flex;
              align-items: center;

              h4 {
                color: $subtitle;
                font-size: 16px;
                font-weight: 500;
                margin-left: 8px;
              }
            }
          }

          .sell-select-end-date {
            display: flex;
            justify-content: space-between;
            margin-top: 12px;
            margin-bottom: 10px;
          }
        }
      }
    }
  }

  .create-order-btn {
    margin-top: 51px !important;
    margin-bottom: 20px !important;
    width: 194px;
    margin: auto;
  }
}

.sell-order-input {
  border-radius: 5px;
  border: 1px solid $border;
}

.custom-file-input {
  width: 492px;
  height: 41px;
  margin-top: 13px;
}

input[type='file']::file-selector-button {
  margin-right: 20px;
  border-radius: 8px;
  border: 1px solid $border;
  background: #444;
  color: $subtitle;
  cursor: pointer;
  transition: background 0.2s ease-in-out;
  width: 492px;
  height: 41px;
}

input[type='file']::file-selector-button:hover {
  background: #444;
}

.activeType {
  background: white !important;
  color: black !important;
}

@media screen and (max-width: 768px) {
  .form-input-bloc-header {
    width: 100%;
    align-items: flex-start;
  }

  .image-preview {
    margin-right: 0;
    max-width: 80px;
  }

  .package-dimensions {
    flex-wrap: wrap;
    flex-direction: row;
    
    .form-input-bloc {
      width: 48%;
      margin-right: 4%;

      h3 {
        margin-bottom: 3px;
      }
    }

    .form-input-bloc:nth-child(2n) {
      margin-right: 0;
    }
  }

  .custom-file-input,
  input[type='file']::file-selector-button {
    width: 100%;
  }

  .dialog .sell-order {
  }

  .dialog .sell-order-header {
    align-items: flex-start;

    button {
      width: 30px;
      height: 30px;

      img {
        margin-top: 2px;
      }
    }
  }

  .dialog .sell-order-bottle {
    align-items: flex-start;
    padding: 10px;

    div {
      margin-left: 0;
    }

    p {
      max-width: 100%;
    }
  }

  .sell-form-options .sell-end-date-inputs {
    align-items: flex-start;

    .sell-end-date-actions {
      align-items: flex-start;

      div {
        margin-top: 10px;
        margin-bottom: 10px;
      }
    }

    .sell-select-end-date {
      align-items: flex-start;
      margin-top: 10px;
    }
  }

  .create-order-btn {
    width: 100%;
  }
}

@media screen and (max-width: 480px) {
  .form-input-bloc-header {
    width: 100%;
  }

  .dialog {
    .sell-order {
      .sell-order-bottle {
        flex-direction: row;
        padding: 5px;
        margin: -10px auto 10px auto;
      }

      form {
        padding-left: 0px;
        padding-right: 0px;
        .package-dimensions {
        .form-input-bloc {
          width: 100%;
          margin-top: 10px;
          h3 {
            font-size: 14px;
          
          }
      }
    }

      .create-order-btn {
        margin-top: 20px;
      }

      }
    }
  }


  .image-preview {
    img {
    max-width: 60px;
    padding-right: 10px;
    }
  }

  .dialog .sell-order {
    padding: 5px;
  }

  .dialog .sell-order-header {
    padding: 8px;
  }

  .sell-form-options .sell-end-date-inputs .sell-end-date-actions,
  .sell-form-options .sell-end-date-inputs .sell-select-end-date {
    align-items: flex-start;
  }

  .sell-order-bottle div h2 {
    font-size: 16px;
  }

  .sell-order-bottle div p {
    font-size: 14px;
  }

  .sell-form-options h4 {
    font-size: 14px;
  }

  .create-order-btn {
    margin-top: 20px;
  
  }

  .package-dimensions {
    flex-wrap: initial;
  }
  .package-dimensions .form-input-bloc {
    width: 100%;
    margin-bottom: 10px;
    margin-right: 2%;
  }

  .package-dimensions .form-input-bloc:nth-child(2n) {
    margin-right: 0;
  }
}

</style>
