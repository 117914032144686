<template>
  <div class="dialog">
    <div class="header">
      <div class="sell-order">
        <p v-if="order.order_type === 3" class="first-text">
          Je souhaite obtenir une
        </p>
        <p v-else class="first-text">Je possède une</p>
        <div class="sell-order-bottle" :class="{ removePadding: !firstImage }">
          <img
            v-if="firstImage"
            :alt="getProduct.title"
            :src="firstImage"
            height="58"
            width="58"
          />
          <img
            v-else
            :src="require('@/assets/svg/v2/bottle.svg')"
            alt="Product not found image"
            class="empty-bottle-picture"
            height="58"
            width="58"
          />
          <div>
            <h2>{{ getProduct.title }}</h2>
            <p>{{ getProduct.ticker }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <form action="" enctype="multipart/form-data" @submit.prevent="createOrder">
    <div class="form-input-bloc"></div>
    <div class="package-dimensions">
      <div class="form-input-bloc margin-block-form">
        <h3>{{ $t('product_page.orders.weight_unit') }}</h3>
        <el-select
          v-model="swapForm.weight_unit"
          class="generic-border"
          effect="dark"
        >
          <el-option
            v-for="unit in getLists.unit_of_measurement_weight"
            :key="unit.value"
            :label="unit.label"
            :value="unit.value"
          />
        </el-select>
      </div>
      <div class="form-input-bloc">
        <h3>
          {{ $t('product_page.orders.sell_weight') }} ({{
            swapForm.weight_unit
          }})
        </h3>
        <el-input
          v-model="swapForm.weight"
          :class="{ errorInput: swapFormRegex.length }"
          class="sell-order-input"
          max="20"
          min="1"
          type="number"
        />
      </div>
    </div>
    <div class="package-dimensions">
      <div class="form-input-bloc margin-block-form">
        <h3>{{ $t('product_page.orders.size_unit') }}</h3>
        <el-select
          v-model="swapForm.size_unit"
          class="generic-border"
          effect="dark"
        >
          <el-option
            v-for="unit in getLists.unit_of_measurement_dimensions"
            :key="unit.value"
            :label="unit.label"
            :value="unit.value"
          />
        </el-select>
      </div>
      <div class="form-input-bloc margin-block-form">
        <h3>
          {{ $t('product_page.orders.length') }} ({{ swapForm.size_unit }})
        </h3>
        <el-input
          v-model="swapForm.package_dimensions.length"
          :class="{ errorInput: swapFormRegex.length }"
          class="sell-order-input"
          max="2000"
          min="10"
          type="number"
        />
      </div>
      <div class="form-input-bloc margin-block-form">
        <h3>{{ $t('product_page.orders.width') }} ({{ swapForm.size_unit }})</h3>
        <el-input
          v-model="swapForm.package_dimensions.width"
          :class="{ errorInput: swapFormRegex.width }"
          class="sell-order-input"
          max="2000"
          min="10"
          type="number"
        />
      </div>
      <div class="form-input-bloc">
        <h3>
          {{ $t('product_page.orders.height') }} ({{ swapForm.size_unit }})
        </h3>
        <el-input
          v-model="swapForm.package_dimensions.height"
          :class="{ errorInput: swapFormRegex.height }"
          class="sell-order-input"
          max="2000"
          min="10"
          type="number"
        />
      </div>
    </div>
    <div class="form-input-bloc centered">
      <h3>{{ $t('product_page.orders.sell_pictures') }}</h3>
      <input
        ref="file"
        :multiple="true"
        accept="image/jpg, image/jpeg, image/png"
        class="custom-file-input"
        name="document"
        type="file"
        @change="files"
      />
      <div v-if="selectedFiles.length > 0">
        <div class="image-previews">
          <div
            v-for="(file, index) in selectedFiles"
            :key="index"
            class="image-preview"
            @mouseenter="showDeleteButton(index)"
            @mouseleave="hideDeleteButton(index)"
          >
            <img
              :src="file.preview"
              alt="Selected Image"
              class="image-preview"
            />

            <button class="delete-button" @click="removeFile(index)">
              <img
                id="delete"
                :src="require(`@/assets/svg/v2/orders/trash.svg`)"
                alt="Delete"
              />
            </button>
          </div>
        </div>
      </div>
    </div>
    <span v-if="swapFormRegex.file" class="error" style="margin: 10px 0"
      >{{ $t('product_page.orders.max_min_pictures') }}</span
    >
    <div class="sell-form-options">
      <h4>{{ $t('product_page.orders.sell_options') }}</h4>
      <div class="sell-end-date-bloc">
        <div class="sell-end-date-inputs">
          <div class="sell-end-date-actions">
            <div class="sell-end-date-select">
              <img alt="End date" src="@/assets/svg/v2/settings/hour.svg" />
              <h4>{{ $t('product_page.orders.sell_end_date') }}</h4>
            </div>
            <el-switch
              v-model="swapForm.endDate"
              class="switch"
              style="
                --el-switch-on-color: #2667ff;
                --el-switch-off-color: #555555;
              "
            />
          </div>
          <div v-if="swapForm.endDate" class="sell-select-end-date">
            <el-date-picker
              v-model="swapForm.date"
              :class="{ errorInput: swapFormRegex.dateBeforeNow }"
              class="generic-border"
              format="DD/MM/YYYY"
              placeholder="Choose a day"
              type="date"
              value-format="YYYY-MM-DD"
            />

            <el-input
              v-model="swapForm.time"
              :class="{
                errorInput:
                  swapFormRegex.countdown || swapFormRegex.dateBeforeNow,
              }"
              class="sell-order-input-small"
              placeholder="12:00"
              value-format="HH:mm"
              maxlength="5"
              prefix-icon="el-icon-time"
            />
          </div>
          <span
            v-if="swapFormRegex.dateBeforeNow"
            class="error"
            style="margin: 10px 0"
            >{{ $t('product_page.orders.add_min_one_hour') }}</span
          >
        </div>
      </div>
    </div>
  </form>
  <button
    :disabled="isLoading"
    v-loading="isLoading"
    element-loading-background="rgba(0, 0, 0, 0.4)"
    class="create-order-btn generic-btn"
    @click.prevent="updateOrder"
  >
  {{ $t('product_page.orders.update_order') }}
  </button>
</template>
<script>
import { ElMessage, ElNotification } from 'element-plus'

import { mapActions, mapGetters } from 'vuex'
import m from 'moment'

export default {
  emits: ['close'],
  props: {
    order: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      selectedFiles: [],
      isLoading: false,
      productToSwap: null,
      swapForm: {
        price: null,
        weight_unit: 'KGS',
        size_unit: 'CM',
        quantity: 1,
        weight: 0,
        package_dimensions: {
          length: 0,
          width: 0,
          height: 0,
        },
        endDate: false,
        date: null,
        time: null,
      },
      swapFormRegex: {
        quantity: false,
        endDate: false,
        date: false,
        time: false,
        dateBeforeNow: false,
        length: false,
        width: false,
        height: false,
        file: false,
        weight: false,
      },
    }
  },
  mounted() {
    this.updateSwapFormFromOrder(this.order)
    this.$store.commit('RESET_SEARCH', { key: 'productPage', products: [] })
  },
  computed: {
    ...mapGetters({
      getCreateOrderDialog: 'getCreateOrderDialog',
      getProduct: 'getProduct',
      getLists: 'getLists',
      getProducts: 'getProducts',
      getSearchPending: 'getSearchPending',
    }),
    firstImage() {
      if (this.getProduct.files_attachments) {
        return this.getProduct.files_attachments[0]
      }
      return ''
    },
    sellDateFormat() {
      if (this.swapForm.date !== null && this.swapForm.time !== null) {
        return `${this.swapForm.date} ${this.swapForm.time}`
      } else {
        return m().add(1, 'years').format('YYYY-MM-DD HH:mm')
      }
    },
  },
  watch: {
    'swapForm.time': function () {
      if (this.swapForm.time) {
        let slashCount = 0
        for (var i = 0; i < this.swapForm.time; i++) {
          if (this.swapForm.time[i] === ':') {
            slashCount++
          }
        }

        if (slashCount > 1) {
          this.swapForm.time = ''
        }

        if (this.swapForm.time.length === 2 && slashCount === 0) {
          this.swapForm.time += ':'
        }
      }
    },
    sellDateFormat() {
      const now = m().add(1, 'hours').format('YYYY-MM-DD HH:mm')

      if (this.swapForm.date && this.swapForm.time) {
        const isBeforeNow = m(
          `${this.swapForm.date} ${this.swapForm.time}`
        ).isBefore(now)

        if (isBeforeNow) {
          this.swapForm.time = null
          this.swapForm.date = null
        } else {
          this.swapFormRegex.dateBeforeNow = false
        }
      }
    },
  },
  methods: {
    updateSwapFormFromOrder(order) {
      if (order) {
        this.selectedFiles = order?.pictures?.map((url) => ({
          preview: url,
        }))
        this.swapForm.weight_unit = order.weight_unit
        this.swapForm.size_unit = order.size_unit
        this.swapForm.weight = order.package_weight
        this.swapForm.package_dimensions.length =
          order.package_dimensions.length
        this.swapForm.package_dimensions.width = order.package_dimensions.width
        this.swapForm.package_dimensions.height =
          order.package_dimensions.height
      }
    },
    removeBottle() {
      this.productToSwap = null
    },
    getWeight(bottleSize, weightUnit) {
      const weightItem = this.getLists.minimum_weight.find(
        (item) => item.bottle_size === parseInt(bottleSize)
      )
      if (!weightItem) return null

      switch (weightUnit) {
        case 'KGS':
          return weightItem.weight / 1000
        case 'LBS':
          return weightItem.weight / 453.59237
        case 'OZS':
          return weightItem.weight * 0.035274
        default:
          return null
      }
    },
    checkDuplicateFiles() {
      for (let i = 0; i < this.selectedFiles.length; i++) {
        for (let j = i + 1; j < this.selectedFiles.length; j++) {
          if (this.selectedFiles[i].preview === this.selectedFiles[j].preview) {
            return true
          }
        }
      }
      return false
    },
    returnUrl(pictures) {
      if (pictures) {
        return pictures[0]
      }
      return null
    },
    ...mapActions({
      updateSwapOrder: 'updateSwapOrder',
      autoSearchAuth: 'autocompleteSearchAuth',
    }),
    async updateOrder() {
      const dimensions_regex = /^[1-9][0-9]{0,3}$/
      const hourRegex = /^(?:[0-9]{2}):(?:[0-9]{2})$/

      const payload = {
        product_type: this.order.order_type,
        isbn: this.getProduct.isbn,
        uuid: this.order.uuid,
        weight_unit: this.swapForm.weight_unit,
        size_unit: this.swapForm.size_unit,
        weight: this.swapForm.weight,
        countdown: `${this.swapForm.date} ${this.swapForm.time}`,
        package_dimensions: this.swapForm.package_dimensions,
      }

      if (this.swapForm.endDate) {
        if (m(payload.countdown).isValid()) {
          this.swapFormRegex.countdown = false

          const now = m().toString()
          const countdownAddHour = m(payload.countdown, 'YYYY-MM-DD HH-mm').add(
            1,
            'h'
          )

          if (m(now).isBefore(countdownAddHour)) {
            this.swapFormRegex.dateBeforeNow = false
          } else {
            this.swapFormRegex.dateBeforeNow = true
          }
        } else {
          this.swapFormRegex.dateBeforeNow = true
        }
      } else {
        delete payload.countdown
      }

      if (!dimensions_regex.test(payload.package_dimensions.length)) {
        this.swapFormRegex.length = true
      } else {
        this.swapFormRegex.length = false
      }

      if (this.swapForm.time) {
        if (hourRegex.test(this.swapForm.time)) {
          this.swapFormRegex.time = false
        } else {
          this.swapFormRegex.time = true
        }
      }

      if (
        parseInt(this.blockTradeForm.weight) <
        this.getWeight(this.getProduct.bottle_size, this.swapForm.weight_unit) *
          this.swapForm.quantity
      ) {
        this.swapFormRegex.weight = true
      } else {
        this.swapFormRegex.weight = false
      }

      if (!dimensions_regex.test(payload.package_dimensions.width)) {
        this.swapFormRegex.width = true
      } else {
        this.swapFormRegex.width = false
      }

      if (!dimensions_regex.test(payload.package_dimensions.height)) {
        this.swapFormRegex.height = true
      } else {
        this.swapFormRegex.height = false
      }

      if (this.checkDuplicateFiles()) {
        this.swapFormRegex.file = true
        ElMessage({
          message: this.$t('product_page.orders.duplicate_pictures'),
          type: 'error',
          grouping: true,
          duration: 5000,
        })
      } else if (
        this.selectedFiles.length < Number(this.getProduct.allowed_min_pictures) ||
        this.selectedFiles.length > 8
      ) {
        this.swapFormRegex.file = true
        ElMessage({
          message: this.$t('product_page.orders.max_min_pictures'),
          type: 'error',
          grouping: true,
          duration: 5000,
        })
      } else {
        this.swapFormRegex.file = false
      }
      if (
        !this.swapFormRegex.quantity &&
        !this.swapFormRegex.file &&
        !this.swapFormRegex.countdown &&
        !this.swapFormRegex.dateBeforeNow &&
        !this.swapFormRegex.length &&
        !this.swapFormRegex.width &&
        !this.swapFormRegex.height &&
        !this.swapFormRegex.weight &&
        !this.swapFormRegex.time
      ) {
        const formData = new FormData()
        formData.append('isbn', payload.isbn)
        formData.append('uuid', payload.uuid)
        formData.append('quantity', payload.quantity)
        formData.append('weight', payload.weight)
        formData.append('weight_unit', payload.weight_unit)
        formData.append('bottle_size', this.getProduct.bottle_size)
        formData.append('size_unit', payload.size_unit)
        if (payload.countdown !== undefined) {
          formData.append('countdown', payload.countdown)
        }
        formData.append(
          'package_dimensions',
          JSON.stringify(payload.package_dimensions)
        )

        for (
          let fileCount = 0;
          fileCount < this.selectedFiles.length;
          fileCount++
        ) {
          if (!this.selectedFiles[fileCount].preview.includes('blob:')) {
            formData.append(
              'saved_pictures_attachments',
              this.selectedFiles[fileCount].preview
            )
          } else formData.append('files', this.selectedFiles[fileCount].file)
        }
        this.isLoading = true
        await this.updateSwapOrder(formData).then(() => {
          ElNotification.success({
            title: '',
            message: this.$t('orders.update_order'),
            offset: 100,
          })
          this.$store.commit('CREATE_ORDER_DIALOG', false)
          this.$emit('close')
          this.$store.commit('RESET_SEARCH', { products: [] })
        })
        this.isLoading = false
      }
    },

    closeOrder() {
      this.$store.commit('CREATE_ORDER_DIALOG', null)
    },
    searchProducts(searchProducts, key) {
      if (
        searchProducts.value?.length > 0 ||
        searchProducts.search?.length > 0
      ) {
        if (!this.getSearchPending['productPage']) {
          const formattedPayload = {
            type: key,
            search: searchProducts.value
              ? searchProducts.value
              : searchProducts.search,
            spirit_type: searchProducts.spirit_type,
          }

          if (!formattedPayload.filters) {
            formattedPayload.filters = {
              spirit_type: null,
              country: null,
              age: null,
              vintage: null,
              single_cask: false
            }
          }

          this.last_pending_search = searchProducts

          this.autoSearchAuth(formattedPayload)
        }
      } else {
        this.$store.commit('RESET_SEARCH', { key: key, products: [] })
      }
    },
    setBottleToAdd(product) {
      this.productToSwap = product
    },
    removeproductToSwap() {
      this.productToSwap = null
    },
    files() {
      for (
        let fileCount = 0;
        fileCount < this.$refs.file.files.length;
        fileCount++
      ) {
        if (this.$refs.file.files) {
          const ext =
            /[A-Za-z0-9_\-()ÀÁÂÃÄÅàáâãäåÒÓÔÕÖØòóôõöøÈÉÊËèéêëÇçÌÍÎÏìíîïÙÚÛÜùúûüÿÑñ]+.(jpg|jpeg|png|JPG|PNG|JPEG)$/
          const fileName = this.$refs.file.files[fileCount].name
          const fileSize = this.$refs.file.files[fileCount].size

          if (!ext.test(fileName) || fileSize > 7000000) {
            ElMessage({
              message: this.$t('product_page.orders.pictures_allowed'),
              type: 'error',
              grouping: true,
              duration: 5000,
            })
          } else {
            const file = {
              file: this.$refs.file.files[fileCount],
              preview: URL.createObjectURL(this.$refs.file.files[fileCount]),
              name: this.$refs.file.files[fileCount].name,
            }

            this.selectedFiles.push(file)
          }
        }
      }
    },
    showDeleteButton(index) {
      this.selectedFiles[index] = {
        ...this.selectedFiles[index],
        showDeleteButton: true,
      }
    },

    hideDeleteButton(index) {
      this.selectedFiles[index] = {
        ...this.selectedFiles[index],
        showDeleteButton: false,
      }
    },

    removeFile(index) {
      event.preventDefault()
      this.selectedFiles.splice(index, 1)
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/styles/root.scss';

$border-color: rgba(255, 255, 255, 0.3);
$scrollbar-thumb-color: rgb(38, 102, 255);
$scrollbar-track-color: rgba(0, 0, 0, 0.2);

.second-title {
  color: white;
  font-size: 16px;
  font-weight: 700;
  margin: 20px;
}

.delete-button {
  display: block;
  margin-top: 5px;
  padding: 5px;
  background: transparent;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background 0.3s;
}

.first-text {
  color: grey;
  font-size: 16px;
  font-weight: 700;
  margin: 10px;
}

.header {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

delete-overlay {
  position: absolute;
  top: 0;
  right: 0;
  background: rgba(255, 255, 255, 0.8);
  border: none;
  padding: 5px;
  cursor: pointer;
  transition: background 0.3s;
}

.v-loading-spinner {
  border-radius: 52px;
}

.v-loading-spinner-inner {
  border-radius: 52px;
}

.product-button-remove {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  margin-left: 30px;
  background: $ligh-grey;
}

.delete-overlay img {
  width: 16px;
  height: 16px;
}

.image-previews {
  display: flex;
  flex-wrap: wrap;
  margin-top: 10px;
}

.centered {
  text-align: center;
}

.image-preview {
  min-height: 80px;
  margin-right: 10px;
  margin-bottom: 10px;
  max-width: 100px;
  text-align: center;
  position: relative;

  img {
    max-width: 100%;
    height: auto;
    border-radius: 5px;
  }

  .delete-button {
    display: none;
    position: absolute;
    top: 5px;
    right: 5px;
    padding: 5px;
    background: transparent;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background 0.3s;
  }

  &:hover {
    .delete-button {
      display: block;
    }
  }

  span {
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.package-dimensions {
  display: flex;
}

.dialog {
  z-index: 5;
  position: relative;

  .selected-isbn {
    display: flex;
    border: 1px solid $border;
    color: $grey40;
    margin-top: 12px;
    border-radius: 6px;
    align-items: center;
    justify-content: center;
    padding: 2px 4px;
    max-width: 110px;
    text-align: center;
  }
}

.sell-order-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 24px;
  border-radius: 20px 20px 0px 0px;
  border-radius: 20px 20px 0px 0px;
  background: linear-gradient(
    180deg,
    rgba(244, 63, 94, 0.16) 0%,
    rgba(244, 63, 94, 0) 100%
  );
  border-bottom: 1px solid $border;

  .sell-order-header-title {
    display: flex;
    align-items: center;

    div {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 38px;
      height: 38px;
      background: #f43f5e;
      border-radius: 50%;
      margin-right: 14px;
    }
  }

  button {
    background: transparent;
    border-radius: 76px;
    border: 1px solid $border;
    background: #444;
    width: 38px;
    height: 38px;

    img {
      margin-top: 4px;
    }
  }
}

.sell-order-bottle {
  display: flex;
  align-items: center;
  padding-left: 24px;
  padding-bottom: 26px;
  border-bottom: 1px solid $border;

  div {
    margin-left: 16px;

    h2 {
      color: white;
      font-size: 18px;
      font-weight: 600;
    }

    p {
      border: 1px solid $border;
      color: $grey40;
      margin-top: 12px;
      border-radius: 6px;
      padding: 2px 4px;
      max-width: 120px;
      text-align: center;
    }
  }
}

form {
  flex-direction: column;
  padding-left: 24px;
  padding-right: 24px;

  .margin-block-form {
    margin-right: 10px;
  }

  .form-input-bloc {
    margin-top: 18px;

    h3 {
      color: white;
      font-size: 16px;
      font-weight: 600;
      margin-bottom: 8px;
    }
  }

  .package-dimensions {
    width: 100%;

    .form-input-bloc {
      width: 23%;
    }
  }

  .sell-form-options {
    margin-top: 30px;

    h4 {
      color: $subtitle;
      font-size: 16px;
      font-weight: 600;
    }

    .sell-end-date-bloc {
      .sell-end-date-inputs {
        .sell-end-date-actions {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-top: 20px;

          div {
            display: flex;
            align-items: center;

            h4 {
              color: $subtitle;
              font-size: 16px;
              font-weight: 500;
              margin-left: 8px;
            }
          }
        }

        .sell-select-end-date {
          display: flex;
          justify-content: space-between;
          margin-top: 12px;
          margin-bottom: 10px;
        }
      }
    }
  }
}

.create-order-btn {
  margin-top: 51px !important;
  margin-bottom: 20px !important;
  width: 194px;
  margin: auto;
}

.sell-order-input {
  border-radius: 5px;
  border: 1px solid $border;
}

.sell-order-input-small {
  border-radius: 5px;
  border: 1px solid $border;
  max-width: 30%;
}

.custom-file-input {
  width: 492px;
  height: 41px;
  margin-top: 13px;
}
.empty-bottle {
  margin-top: 20px;

  div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;

    img {
      z-index: 2;
    }

    p {
      color: $subtitle;
      font-size: 16px;
      font-weight: 500;
      max-width: 315px;
      text-align: center;
      margin-top: 28px;
    }
  }
}

h3,
.product-title {
  color: white;
}

.search-result-body {
  padding: 2rem 0;
}

.search-result-header {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.search-result {
  height: 23rem;
  overflow: auto;
  border-radius: 12px;
}

.product {
  display: flex;
  align-items: center;
  flex-direction: row;
  border: 1px solid $border-color;
  border-radius: 12px;
  margin: 1rem;
  padding: 1rem;
  gap: 1rem;
  background-color: #444444;

  &-image,
  &-info .product-data span:first-child,
  .not-found-image__wrapper {
    border: 1px solid $border-color;
    border-radius: 8px;
  }

  &-image,
  .not-found-image__wrapper {
    height: 70px;
    width: 70px;
  }

  .not-found-image__wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &-info {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    gap: 12px;
    justify-content: center;
    padding-left: 1rem;

    & .product-title {
      font-size: 18px;
    }

    & .product-data {
      span:first-child {
        color: $border-color;
        padding: 4px 6px;
        font-size: 14px;
      }

      span:nth-child(2) {
        font-size: 18px;
        color: rgba(255, 255, 255, 0.6);
        margin-left: 10px;
      }
    }
  }

  &-button {
    background-color: transparent;
    color: white;
    font-weight: bold;
    padding: 6px 8px;
    border: none;
    border-radius: 8px;
  }
}

.search-result::-webkit-scrollbar {
  width: 10px;
}

.search-result::-webkit-scrollbar-track {
  background: $background;
}

.search-result::-webkit-scrollbar-thumb {
  background-color: white;
  border-radius: 10px;
}

.search-result::-webkit-scrollbar-thumb:hover {
  background-color: $grey40;
}

input[type='file']::file-selector-button {
  margin-right: 20px;
  border-radius: 8px;
  border: 1px solid $border;
  background: #444;
  color: $subtitle;
  cursor: pointer;
  transition: background 0.2s ease-in-out;
  width: 492px;
  height: 41px;
}

input[type='file']::file-selector-button:hover {
  background: #444;
}

.activeType {
  background: white !important;
  color: black !important;
}
</style>
