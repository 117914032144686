<template>
  <div class="shipping-details">
    <div class="shipping-header">
      <div class="shipping-informations">
        <div class="informations">
          <p class="title">
            {{ $t('product_page.orders.track_number') }}
          </p>
          <p class="reference">{{ order.shipment.tracking_number }}</p>
        </div>
        <div class="tag-pay" v-if="!order.shipment.package_status">
          {{ $t('product_page.orders.pay') }}
        </div>
        <div class="tag-transit" v-if="order.shipment.package_status === 'In Transit'">
          {{ $t('product_page.orders.in_transit') }}
        </div>
        <div class="tag-transit" v-if="order.shipment.package_status === 'Available for pickup'">
          {{ $t('product_page.orders.pickup_available') }}
        </div>
        <div class="tag-delivered" v-if="order.shipment.package_status === 'Delivered'">
          {{ $t('product_page.orders.delivery_end') }}
        </div>
      </div>
      <a
        class="follow-button"
        :href="
          'https://www.ups.com/track?tracknum=' + order.shipment.tracking_number
        "
        target="_blank"
        rel="noopener noreferrer"
        >{{ $t('product_page.orders.track') }}</a
      >
    </div>
    <div class="shipping-details-footer v3-disabled">
      <div class="shipping-details-footer-left">
        <icon>
          <img src="@/assets/svg/v2/products/truck.svg" alt="box" />
        </icon>
        <!-- <p class="title">{{ $t('account_orders_list.dialog_shipping.shipment_date') }}</p> -->
        <p class="info-text">15 septembre 2023</p>
      </div>
      <div class="shipping-details-footer-right">
        <icon> <img src="@/assets/svg/v2/home.svg" alt="box" /> </icon>
        <!-- <p class="title">{{ $t('account_orders_list.dialog_shipping.shipment_address') }}</p> -->
        <p class="info-text">12 rue Arc de Triomphe,</p>
        <p class="info-text">75001, Paris</p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  props: {
    order: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {}
  },
  computed: {
    ...mapGetters({
      getUserDetails: 'getUserDetails',
    }),
  },
}
</script>

<style scoped lang="scss">
@import '@/assets/styles/root.scss';

.shipping-details {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.shipping-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.shipping-informations {
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  border-radius: 10px;
  padding: 24px;
}

.shipping-details-footer {
  display: flex;
  justify-content: space-between;
  background-color: #444444;
  width: 100%;
  padding-bottom: 20px;
}

.shipping-details-footer-left,
.shipping-details-footer-right {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin: 15px;
  margin-bottom: 0;

  img {
    height: 24px;
    margin-bottom: 8px;
  }
}

.shipping-details-footer-right {
  padding-right: 40px;
}

.tag-pay, .tag-transit, .tag-delivered {
  color: #add7f6;
  padding: 4px 8px;
  border-radius: 10px;
  border-color: #add7f6;
  border-style: solid;
  border-width: 1px;
  font-size: 12px;
  margin-top: 15px;
}

.tag-delivered {
  color: #84cc16;
  border-color: #84cc16;
}

.informations {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 10px;
}

.icon {
  margin: 0 10px;
}

.title {
  font-size: 12px;
  margin-bottom: 10px;
}

.reference {
  font-size: 16px;
  color: white;
}

.info-text {
  color: white;
  margin: 0;
  font-size: 14px;
}

.follow-button {
  background-color: $blue;
  color: white;
  margin: 22px 15px;
  border-radius: 40px;
  padding: 7px 12px;
  width: 230px;
  border: 0;
  font-size: 15px;
  text-align: center;
}

@media screen and (max-width: 500px) {
  .shipping-header {
    flex-wrap: wrap;
    justify-content: center;
  }

  .shipping-informations {
    width: auto;
  }

  .follow-button {
    margin: auto 0;
  }
}
</style>
